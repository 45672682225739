exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-card-js": () => import("./../../../src/pages/card.js" /* webpackChunkName: "component---src-pages-card-js" */),
  "component---src-pages-espacio-js": () => import("./../../../src/pages/espacio.js" /* webpackChunkName: "component---src-pages-espacio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-100-ad-100-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/ad-100/ad-100.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-100-ad-100-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-mexico-2025-ad-mexico-2025-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/ad-mexico-2025/ad-mexico-2025.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-mexico-2025-ad-mexico-2025-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-mexico-ad-mexico-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/ad-mexico/ad-mexico.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-ad-mexico-ad-mexico-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-arquitectura-diseno-espana-arquitectura-diseno-espana-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/arquitectura-diseno-espana/arquitectura-diseno-espana.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-arquitectura-diseno-espana-arquitectura-diseno-espana-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-est-living-est-living-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/est-living/est-living.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-est-living-est-living-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-manera-manera-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/manera/manera.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-manera-manera-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-worlds-best-worlds-best-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/worlds-best/worlds-best.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-worlds-best-worlds-best-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-yin-mag-yin-mag-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/press/yin-mag/yin-mag.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-press-yin-mag-yin-mag-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-colomos-apto-colomos-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/apto-colomos/apto-colomos.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-colomos-apto-colomos-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-elprado-apto-elprado-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/apto-elprado/apto-elprado.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-elprado-apto-elprado-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-platon-apto-platon-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/apto-platon/apto-platon.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-apto-platon-apto-platon-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-argentina-casa-argentina-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/casa-argentina/casa-argentina.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-argentina-casa-argentina-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-cedros-casa-cedros-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/casa-cedros/casa-cedros.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-cedros-casa-cedros-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-lostigres-casa-lostigres-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/casa-lostigres/casa-lostigres.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-lostigres-casa-lostigres-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-primavera-casa-primavera-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/casa-primavera/casa-primavera.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-casa-primavera-casa-primavera-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-conjunto-1001-conjunto-1001-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/conjunto-1001/conjunto-1001.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-conjunto-1001-conjunto-1001-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-hotel-omun-hotel-omun-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/hotel-omun/hotel-omun.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-hotel-omun-hotel-omun-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-parks-gdl-parks-gdl-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/sergiotbh/react-projects/aagnes/src/sources/projects/parks-gdl/parks-gdl.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-users-sergiotbh-react-projects-aagnes-src-sources-projects-parks-gdl-parks-gdl-mdx" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

